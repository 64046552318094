import React from "react"

import styles from "./blockquote.module.scss"

const Blockquote = ({ text }) => (
  <blockquote className={styles.blockquote}>
    <span className={styles.mark}>“</span>
    {text}
    <span className={styles.mark}>”</span>
  </blockquote>
)

export default Blockquote
