import React from "react"
import Image from "../../image/image"
import cx from "classnames"
import Reveal from "react-reveal/Reveal"

import styles from "./inlineImage.module.scss"

const InlineImage = ({ alignment, image, alt, title, breakpoint }) => {
  const isGif = image?.extension === "gif"
  const width = image?.metadata.dimensions.width

  return (
    <Reveal effect="fadeInDown">
      <div
        className={cx(styles.inlineImage, styles[alignment], isGif ? "" : styles.padding, styles[breakpoint])}
        style={{ maxWidth: `${width}px` }}
      >
        <Image image={image} alt={alt} title={title} width={width} />
      </div>
    </Reveal>
  )
}
export default InlineImage
