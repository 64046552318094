import React from "react"

import BlockContent from "../blockContent"

const TwoColumns = ({ columns }) => (
  <div className="grid">
    <div className="girdItem small-12 medium-6">
      <BlockContent blocks={columns.col1} />
    </div>
    <div className="girdItem small-12 medium-6">
      <BlockContent blocks={columns.col2} />
    </div>
  </div>
)

export default TwoColumns
