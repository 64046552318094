import React from "react"
import Img from "gatsby-image"
import { getFluidGatsbyImage } from "gatsby-source-sanity"

import { config } from "../../helpers/clientConfig"

import imageStyles from "./image.module.scss"

const Image = ({ image, alt, width, height, styles, title }) => {
  const fluidProps = getFluidGatsbyImage(image, { maxWidth: width, maxHeight: height }, config)
  const isGif = image?.extension === "gif"

  return (
    <figure style={{ width: "100%", height: "100%" }}>
      {isGif ? (
        <img className={styles} src={image.url} alt={alt} width="100%" />
      ) : (
        <Img
          className={styles}
          fluid={fluidProps}
          alt={alt}
          imgStyle={{
            objectFit: "contain",
          }}
        />
      )}
      <figcaption className={imageStyles.caption}>{title}</figcaption>
    </figure>
  )
}
export default Image
