import React from "react"
import cx from "classnames"

import BlockContent from "../blockContent"

import LocationIcon from "../../../../assets/svg/location.svg"

import styles from "./address.module.scss"

const Address = ({ data }) => (
  <div className={styles.address}>
    <p className={styles.title}>
      <LocationIcon className={cx(styles.icon, styles[data.icon])} />
      {data.title}
    </p>
    <div className={styles.text}>
      <BlockContent blocks={data.address} />
    </div>
  </div>
)

export default Address
