import React from "react"

import styles from "./toc.module.scss"

const TocManual = ({ list, title }) => {
  return (
    <div className={styles.toc}>
      {title ? <p className={styles.title}>{title}</p> : null}
      <ol className={styles.list}>
        {list.map(item => (
          <li key={item.children[0].text}>
            <a href={`#${item.markDefs[0]?.link}`}>{item.children[0].text}</a>
          </li>
        ))}
      </ol>
    </div>
  )
}

export default TocManual
