import React from "react"

import SocialIcon from "./socialIcon"

import styles from "./social.module.scss"

const Social = ({ data }) => (
  <div className={styles.social}>
    <div className={styles.socialItem}>
      <a href={data.url}>
        <SocialIcon type={data.platform} /> {data.name || data.platform}
      </a>
    </div>
  </div>
)

export default Social
