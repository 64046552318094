import React from "react"

import BlockContent from "../blockContent/blockContent"

import styles from "./table.module.scss"

const Table = props => {
  const heading = props.node.heading
  const rows = props.node.rows
  const rowsArr = Object.entries(rows)
  rowsArr.shift() // get rid of "_type"

  const Row = ({ row }) => (
    <tr>
      {row.flat().map((cell, index) =>
        index !== 0 ? (
          // single cell
          <td data-before={heading && heading[index - 1]?.mobileText}>
            <BlockContent blocks={cell.cell} />
          </td>
        ) : null
      )}
    </tr>
  )

  return (
    <table className={styles.table}>
      <thead>
        <tr>
          {heading?.map(cell => (
            <th>
              <BlockContent blocks={cell.cell} />
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {/* TABLE BODY */}
        {rowsArr.map((row, index) => (
          <Row row={row} />
        ))}
      </tbody>
    </table>
  )
}

export default Table
