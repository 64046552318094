import React from "react"

import BlockContent from "../blockContent"
import Bulb from "../../../../assets/svg/bulb.svg"

import styles from "./tip.module.scss"

// Initially "text" was the main version,
// mid content entry there was a request to replace string with text field, that's why here are two options - to not break authoring
// idealy this is todo: when all tips are migrated to use tipText, remove text field

const Tip = ({ text, blocks }) => (
  <div className={styles.tip}>
    <Bulb className={styles.icon} />
    <div className={styles.text}>
      {blocks?.length ? (
        <BlockContent blocks={blocks} />
      ) : (
        <p>
          <span className={styles.blue}>Tip: </span>
          {text}
        </p>
      )}
    </div>
  </div>
)

export default Tip
