import React from "react"

import styles from "./iframeEmbed.module.scss"

//partially migrated from watershed

const IframeEmbed = props => {
  const setLength = (custom, aspect, i) => {
    let customAspect = null
    if (custom?.width && custom?.height) {
      customAspect = `${custom.width}:${custom.height}`
    }
    const aspectRatio = customAspect || aspect || "16:9"

    return aspectRatio.split(":")[i]
  }

  const aspectWidth = setLength(props.custom, props.aspect, 0)
  const aspectHeight = setLength(props.custom, props.aspect, 1)
  let aspectRatio = `${(aspectHeight / aspectWidth) * 100}%`

  return (
    <div className={styles.iframeWrapper} style={{ paddingTop: aspectRatio }}>
      <iframe title={props.title} className={styles.iframe} allowFullScreen frameBorder="0" src={props.url} />
    </div>
  )
}

export default IframeEmbed
