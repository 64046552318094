/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

import Schema from "./schema"

function SEO({
  description,
  title,
  isBlogPost,
  breadcrumbs,
  canonical,
  author,
  image,
  openGraphImage,
  metaTitle,
  path,
  datePublished,
  dateModified,
  noIndex,
}) {
  const site = useStaticQuery(
    graphql`
      {
        sanityConfig {
          ...MetaDataFragment
        }
      }
    `
  )

  const { sanityConfig: config } = site

  const lang = config.lang
  const alrernativeTitle = `${title || metaTitle | " "} | ${config.titleSection} | ${config.title}`
  const metaTitleSet = `${metaTitle || title} | ${config.title}`
  const metaDescription = description || config.description
  const metaImage = openGraphImage?.asset.url || image || config.ogImage.asset.url

  // for schema only
  const canonicalPath = canonical || `${process.env.GATSBY_SITE_URL}${path || ""}`

  return (
    <>
      <Helmet
        htmlAttributes={{
          lang,
        }}
        title={metaTitleSet}
        titleTemplate={`%s`}
        link={
          canonical
            ? [
                {
                  rel: "canonical",
                  href: canonical,
                },
              ]
            : []
        }
        meta={[
          {
            name: `description`,
            content: metaDescription,
          },
          {
            property: `og:title`,
            content: metaTitleSet,
          },
          {
            property: `og:description`,
            content: metaDescription,
          },
          {
            property: `og:image`,
            content: metaImage,
          },
          {
            property: `og:type`,
            content: `website`,
          },
          {
            name: `twitter:card`,
            content: `summary_large_image`,
          },
          {
            name: `twitter:title`,
            content: metaTitleSet,
          },
          {
            name: `twitter:description`,
            content: metaDescription,
          },
          {
            name: `twitter:image`,
            content: metaImage,
          },
        ]}
      />
      {noIndex && (
        <Helmet>
          <meta name="robots" content="noindex" />
        </Helmet>
      )}

      <Schema
        isBlogPost={isBlogPost}
        url={canonical}
        title={metaTitleSet}
        image={metaImage}
        description={metaDescription}
        datePublished={datePublished}
        dateModified={dateModified}
        canonicalUrl={canonicalPath}
        author={author}
        organization={config}
        defaultTitle={alrernativeTitle}
        breadcrumbs={breadcrumbs}
      />
    </>
  )
}

SEO.defaultProps = {
  lang: `en`,
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  title: PropTypes.string,
}

export default SEO
