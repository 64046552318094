import React from "react"
import BaseBlockContent from "@sanity/block-content-to-react"

// block content specific
import FormattedLink from "../formattedLink/formattedLink"
import TocManual from "./toc/tocManual"
import InlineImage from "./inlineImage/inlineImage"
import Tip from "./tip/tip"
import RecommendedReading from "./recommendedReading/recommendedReading"
import Blockquote from "./blockquote/blockquote"
import TwoColumns from "./twoColumns/twoColumns"
import Address from "./address/address"

// global
import VideoEmbed from "../videoEmbed/videoEmbed"
import Button from "../button/button"
import Table from "../table/table"
import CookieButton from "../cookies/cookieButton"
import Check from "../../../assets/svg/check.svg"
import Social from "../social/social.js"

import slugify from "../../helpers/slugify"

import styles from "./blockContent.module.scss"

const BlockContent = ({ blocks }) => {
  const serializers = {
    types: {
      block: props => {
        const style = props.node.style || "normal"

        if (/^h\d/.test(style)) {
          const text = props.node.children.map(item => item.text.trim()).join(" ")
          const elementId = slugify(text)
          return React.createElement(style, { id: `${elementId}` }, props.children)
        }

        if (style === "blockquote") {
          return <Blockquote text={props.children} />
        }

        if (props.children.indexOf("[manage-cookie-acceptance]") !== -1) return <CookieButton />

        return BaseBlockContent.defaultSerializers.types.block(props)
      },
      inlineImage: props => (
        <InlineImage
          alignment={props.node.alignment}
          image={props.node.image.asset}
          alt={props.node.image.alt}
          title={props.node.image.title}
          breakpoint={props.node.breakpoint}
        />
      ),
      recommendedReading: props => <RecommendedReading {...props.node} />,
      tip: props => <Tip text={props.node.text} blocks={props.node.tipText} />,
      tocManual: props => <TocManual list={props.node.text} title={props.node.tocTitle} />,
      twoColumns: props => <TwoColumns columns={props.node} />,
      videoEmbed: props => <VideoEmbed {...props.node} noPadding />,
      externalLink: props => (
        <Button link={props.node.url} {...props.node}>
          {props.node.label}
        </Button>
      ),
      internalLink: props => (
        <Button link={props.node.url} {...props.node}>
          {props.node.label}
        </Button>
      ),
      resourceButton: props => (
        <Button link={props.node?.resource?.upload?.url} {...props.node} type="btn-primary" newTab>
          {props.node.label}
        </Button>
      ),
      table: props => <Table {...props} />,
      socialPlatform: props => <Social data={props.node} />,
      address: props => <Address data={props.node} />,
    },
    marks: {
      link: ({ mark, children }) => {
        return (
          <FormattedLink type="link-pink" {...mark} link={mark.href || mark.link}>
            {children}
          </FormattedLink>
        )
      },
      pink: ({ children }) => <span className={styles.pink}>{children}</span>,
      blue: ({ children }) => <span className={styles.blue}>{children}</span>,
      check: ({ children }) => (
        <span className={styles.check}>
          <Check /> {children}
        </span>
      ),
      id: ({ mark, children }) => <span id={mark.id?.current}>{children}</span>,
    },
  }

  return <BaseBlockContent className={styles.textStyle} blocks={blocks} serializers={serializers} />
}
export default BlockContent
