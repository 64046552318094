import React from "react"

import Book from "../../../../assets/svg/book.svg"
import FormattedLink from "../../formattedLink/formattedLink"

import styles from "./recommendedReading.module.scss"

const RecommendedReading = props => {
  return (
    <div className={styles.recommendedReading}>
      <Book className={styles.icon} />
      <div>
        <p className={styles.text}>{props.text || "Recommended Reading:"}</p>
        <FormattedLink link={props.url} {...props} />
      </div>
    </div>
  )
}

export default RecommendedReading
