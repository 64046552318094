import React from "react"
import Helmet from "react-helmet"

const Schema = ({
  author,
  canonicalUrl,
  datePublished,
  dateModified,
  defaultTitle,
  description,
  image,
  isBlogPost,
  organization,
  title,
  url,
  breadcrumbs,
}) => {
  const breadcrumbsSteps =
    breadcrumbs?.length &&
    breadcrumbs.map((item, index) => {
      return {
        type: "listItem",
        position: index + 1,
        name: item.title,
        item: `${process.env.GATSBY_SITE_URL}/${item.link}`,
      }
    })

  const baseSchema = [
    {
      "@context": "http://schema.org",
      "@type": "WebSite",
      url: organization.url,
      name: title,
      alternateName: defaultTitle,
    },
    {
      "@context": "http://schema.org",
      "@type": "WebPage",
      url: organization.url,
      name: title,
      alternateName: defaultTitle,
      datePublished,
      dateModified,
      isPartOf: organization.url,
      inLanguage: "en",
      primaryImageOfPage: image,
    },
  ]

  const schema = isBlogPost
    ? [
        ...baseSchema,
        breadcrumbs.length
          ? {
              "@context": "http://schema.org",
              "@type": "BreadcrumbList",
              itemListElement: [...breadcrumbsSteps],
            }
          : null,
        {
          "@context": "http://schema.org",
          "@type": "BlogPosting",
          url,
          name: title,
          alternateName: defaultTitle,
          headline: title,
          image: {
            "@type": "ImageObject",
            url: image,
          },
          description,
          author: {
            "@type": "Person",
            name: author,
          },
          publisher: {
            "@type": "Organization",
            url: organization.url,
            logo: organization.ogImage.asset.url, // todo: doesn't work? https://search.google.com/structured-data/testing-tool/u/0/ has a problem with it
            name: organization.title,
          },
          mainEntityOfPage: {
            "@type": "WebSite",
            "@id": canonicalUrl,
          },
          datePublished,
          dateModified,
          inLanguage: "en",
        },
      ]
    : baseSchema

  return (
    <Helmet>
      {/* Schema.org tags */}
      <script type="application/ld+json">{JSON.stringify(schema)}</script>
    </Helmet>
  )
}

export default Schema
