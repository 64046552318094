import React from "react"
import ReactPlayer from "react-player"

import Wrapper from "../layout/wrapper/wrapper"
import IframeEmbed from "../iframeEmbed/iframeEmbed"

import styles from "./videoEmbed.module.scss"

const VideoEmbed = ({ url, noPadding, instilled }) => {
  return (
    <Wrapper noPadding={noPadding}>
      {instilled?.url ? (
        <IframeEmbed {...instilled} />
      ) : (
        <div className={styles.videoEmbed}>
          <ReactPlayer width="100%" height="100%" url={url} controls />
        </div>
      )}
    </Wrapper>
  )
}
export default VideoEmbed
